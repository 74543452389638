import { render, staticRenderFns } from "./index.vue?vue&type=template&id=44521b0f&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSlideCarousel: require('/workspace/components/AppSlideCarousel.vue').default,AppVideo: require('/workspace/components/AppVideo.vue').default,AppMap: require('/workspace/components/AppMap.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import reframe from 'reframe.js'
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // reframe('video')
  },
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h5",class:{ 'text-center': _vm.$device.isMobile }},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$config.appMetadata.facebook),expression:"$config.appMetadata.facebook"}]},[_c('a',{staticClass:"text-white mr-3",class:{
        'text-dark': _vm.dark,
        'mx-3': _vm.$device.isMobile,
      },attrs:{"href":_vm.$config.appMetadata.facebook}},[_c('b-icon-facebook')],1)]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$config.appMetadata.instagram),expression:"$config.appMetadata.instagram"}]},[_c('a',{staticClass:"text-white mr-3",class:{
        'text-dark': _vm.dark,
        'mx-3': _vm.$device.isMobile,
      },attrs:{"href":_vm.$config.appMetadata.instagram}},[_c('b-icon-instagram')],1)]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$config.appMetadata.youtube),expression:"$config.appMetadata.youtube"}]},[_c('a',{staticClass:"text-white mr-3",class:{
        'text-dark': _vm.dark,
        'mx-3': _vm.$device.isMobile,
      },attrs:{"href":_vm.$config.appMetadata.youtube}},[_c('b-icon-youtube')],1)]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$config.appMetadata.twitter),expression:"$config.appMetadata.twitter"}]},[_c('a',{staticClass:"text-white mr-3",class:{
        'text-dark': _vm.dark,
        'mx-4': _vm.$device.isMobile,
      },attrs:{"href":_vm.$config.appMetadata.twitter}},[_c('b-icon-twitter')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  computed: {
    orderedSlides() {
      const slides = [...this.slides]
      return slides.sort((a, b) => a.order - b.order)
    },
  },

  methods: {
    pickDeviceImageSource(slide) {
      const { baseUrl } = this.$config
      const { small, medium } = slide.image.formats
      if (this.$device.isMobile) {
        return baseUrl + small.url
      }
      if (this.$device.isTablet) {
        return baseUrl + medium.url
      }
      return baseUrl + slide.image.url
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppServiceCard from './AppServiceCard.vue'
import AppActiveCard from './AppActiveCard.vue'

export default {
  components: {
    AppServiceCard,
    AppActiveCard,
  },

  props: {
    services: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    activeService: {},
  }),

  computed: {
    topThreeServices() {
      const services = [...this.services]
      services.pricings = services.map((price) =>
        price.pricings.sort((a, b) => a.price - b.price)
      )

      const sortedServices = services.sort((a, b) => a.id - b.id)
      return sortedServices.slice(0, 3)
    },
  },

  methods: {
    setActiveService(service) {
      if (this.activeService?.id !== service.id) {
        this.activeService = service
        this.scrollToPageTop()
      } else {
        this.activeService = {}
      }
    },

    scrollToPageTop() {
      const el = this.$refs.cardContainer
      const coord = el.getBoundingClientRect().top + window.scrollY - 25
      window.scroll({ top: coord, behavior: 'smooth' })
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppSocialBar from '../social/AppSocialBar.vue'
export default {
  components: {
    AppSocialBar,
  },
  props: {
    metadata: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    selected: '',
  }),

  computed: {
    hasActiveService() {
      return JSON.stringify({ ...this.service }) !== '{}'
    },
  },

  watch: {
    service(oldService, newService) {
      if (oldService.id !== newService.id) {
        this.selected = ''
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppSlideCarousel from '../components/AppSlideCarousel.vue'
import AppServiceCards from '../components/offered-services/AppServiceCards.vue'
import AppVideo from '../components/AppVideo.vue'
import AppMap from '../components/AppMap.vue'
import AppFooter from '../components/footer/AppFooter.vue'

export default {
  components: {
    AppSlideCarousel,
    AppServiceCards,
    AppVideo,
    AppMap,
    AppFooter,
  },

  data: () => ({
    services: [],
    slides: [],
    address: {},
  }),

  async fetch() {
    this.services = await this.$axios.$get('services')
    this.slides = await this.$axios.$get('slideshows')
    this.address = await this.$axios.$get('address')
  },
}

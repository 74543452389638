//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reframe from 'reframe.js'

export default {
  props: {
    lon: {
      type: Number,
      required: true,
    },
    lat: {
      type: Number,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mapDisplayLink() {
      const { lon, lat } = this
      return `https://www.openstreetmap.org/export/embed.html?bbox=${
        lon * 0.999999
      }%2C${lat * 0.999999}%2C${lon * 1.000001}%2C${
        lat * 1.000001
      }&amp;layer=mapnik&amp;marker=${lat}%2C${lon}`
    },
    mapAppLink() {
      const { lon, lat } = this
      return `geo:${lat},${lon}?z=20`
    },
    mapLargeLink() {
      const { lon, lat } = this
      return `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}#map=19/${lat}/${lon}`
    },
  },
  mounted() {
    reframe('iframe')
  },
}

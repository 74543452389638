var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-4"},model:{value:(_vm.hasActiveService),callback:function ($$v) {_vm.hasActiveService=$$v},expression:"hasActiveService"}},[_c('b-jumbotron',{attrs:{"bg-variant":"light"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"h2",class:{
          'display-4': _vm.$device.isDesktop,
        }},[_vm._v("\n        "+_vm._s(_vm.service.title)+"\n      ")])]},proxy:true}])},[_vm._v(" "),_c('p',{staticClass:"h-2"},[_vm._v("\n      "+_vm._s(_vm.service.text)+"\n    ")]),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Wählen Sie Ihre Wohlfühlzeit:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ariaDescribedby = ref.ariaDescribedby;
return _vm._l((_vm.service.pricings),function(pricing){return _c('b-form-radio',{key:pricing.id,attrs:{"aria-describedby":ariaDescribedby,"name":"serivce-selection","value":pricing.time},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(_vm._s(pricing.time)+" Minuten à "+_vm._s(pricing.price)+",00 €")])})}}])}),_vm._v(" "),_c('hr',{staticClass:"my-4"}),_vm._v(" "),_c('Nuxt-link',{attrs:{"to":{
        path: 'termin',
        query: {
          serviceName: _vm.service.title,
          serviceTime: _vm.selected,
        },
      }}},[_c('b-button',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.service.title)+" buchen ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }